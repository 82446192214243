import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import MainLayout from './MainLayout'
import CenterOverlay from '../components/CenterOverlay/CenterOverlay'
import BlogPageTitle from '../components/BlogPageTitle/BlogPageTitle'
import getLanguageCode from '../utils/getLanguageCode'
import BlogCategoriesContainer from '../components/BlogCategoriesContainer/BlogCategoriesContainer'
import LinkedPosts from '../components/LinkedPosts/LinkedPosts'
import BlogPagination from '../components/BlogPagination/BlogPagination'

class BlogCategoryTemplate extends Component {
  componentDidMount () {
    const locale = this.props.pageContext.locale
    const translations = this.props.pageContext.translations
    const langData = { locale, translations }
    this.props.setLangData(langData)
    this.props.showHeroBorder(true)
  }

  render () {
    const metaTitle = this.props.data.page.title
    const metaDescription = this.props.data.page.description
    const pageTitle = this.props.data.page.title
    const locale = this.props.pageContext.locale
    const bookContent = this.props.data.bookContent
    this.props.data.page.mainHeader = this.props.data.mainHeader
    const languageCode = getLanguageCode(locale)
    const { categories, posts } = this.props.data
    let pages = 1
    if (posts && posts.totalCount > 12) {
      pages = Math.ceil(posts.totalCount / 12)
    }
    const homePagePath = this.props.data.homePage.path
    const blogPagePath = this.props.data.blogPage.path
    const { location } = this.props

    return (
      <div>
        <Helmet htmlAttributes={{ lang: languageCode }}>
          <title>{metaTitle}</title>
          <meta name='description' content={metaDescription} />
          <link
            rel='canonical'
            href={`${process.env.GATSBY_SITE_URL}${
              this.props.pageContext.currPath
            }`}
          />
          {this.props.pageContext.translations.map(
            ({ locale_name, path }, i) => {
              if (path === '/ca/' || path === '/en/' || path === '/fr/') {
                return null
              }

              return (
                <link
                  key={i}
                  rel='alternate'
                  hreflang={locale_name.replace('_', '-')}
                  href={`${process.env.GATSBY_SITE_URL}${path}`}
                />
              )
            }
          )}
          {this.props.pageContext.translations.map(
            ({ native_name, path }, i) => {
              if (native_name === 'English') {
                return (
                  <link
                    key={i}
                    rel='alternate'
                    hreflang='x-default'
                    href={`${process.env.GATSBY_SITE_URL}${path}`}
                  />
                )
              }
            }
          )}
        </Helmet>
        <MainLayout
          mainHeaderData={this.props.data.mainHeader}
          bookContent={bookContent}
          locale={locale}
          locationKey={this.props.location.key || ''}
          translations={this.props.pageContext.translations}
        >
          <CenterOverlay noTopPadding={false}>
            <BlogPageTitle text={pageTitle} />
            {categories && categories.edges.length > 0 && (
              <BlogCategoriesContainer
                edges={categories.edges}
                homePagePath={homePagePath}
                blogPagePath={blogPagePath}
                locale={locale}
                location={location}
              />
            )}
            {posts && posts.edges.length > 0 && (
              <LinkedPosts edges={posts.edges} />
            )}
            {posts && pages > 1 && (
              <BlogPagination
                path={this.props.pageContext.paginationPath}
                currentPage={this.props.pageContext.currPage}
                totalCount={posts.totalCount}
              />
            )}
          </CenterOverlay>
        </MainLayout>
      </div>
    )
  }
}

BlogCategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = ({ showHeroBorder, setLangData }) => {
  return { showHeroBorder, setLangData }
}

const mapDispatchToProps = dispatch => {
  return {
    showHeroBorder: show =>
      dispatch({
        type: `SHOW_DESKTOP_HEADER_BORDER`,
        showBorder: show
      }),
    setLangData: langData =>
      dispatch({
        type: `LANG_DATA`,
        langData
      })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogCategoryTemplate)

export const blogCategoryPageQuery = graphql`
  query($id: String!, $locale: String!, $skip: Int!, $limit: Int!) {
    page: wordpressCategory(id: { eq: $id }) {
      title: name
      description
    }
    categories: allWordpressCategory(
      filter: {
        count: { gt: 0 }
        wpml_language_information: { language_code: { eq: $locale } }
      }
    ) {
      edges {
        node {
          id
          name
          count
          slug
          parentElement: parent_element {
            id
          }
        }
      }
    }
    posts: allWordpressPost(
      filter: {
        categories: { elemMatch: { id: {eq: $id} } }
        wpml_language_information: { language_code: { eq: $locale } }
      }
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...WordpressPostContent
        }
      }
      totalCount
    }
    bookContent: wordpressStickyBook(language_code: { eq: $locale }) {
      ...StickyBookContent
    }
    mainHeader: wordpressMainHeader(language_code: { eq: $locale }) {
      ...MainHeaderContent
    }
    homePage: wordpressPage(
      template: { eq: "page-templates/Home.php" }
      wpml_language_information: { language_code: { eq: $locale } }
    ) {
      path
    }
    blogPage: wordpressPage(
      template: { eq: "page-templates/Blog.php" }
      wpml_language_information: { language_code: { eq: $locale } }
    ) {
      path
    }
  }
`
